import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest
}
from '@angular/common/http';

import { Observable } from 'rxjs';
import { LoaderService } from '../components/loader.service';
import { finalize } from 'rxjs/operators';

@Injectable()
export class Interceptor implements HttpInterceptor {
    pendingRequestsCount = 0;

    constructor(private loaderService: LoaderService){

    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.loaderService.show();
        this.pendingRequestsCount++;
        return next.handle(request).pipe(
            finalize(() => {
                this.pendingRequestsCount--;
                if(this.pendingRequestsCount == 0){
                    this.loaderService.hide();
                }
            })
        );
    }
}