<!--begin::Navigation-->
<ul class="navi navi-hover">
  <li class="navi-header pb-1">
    <span class="text-primary text-uppercase font-weight-bold font-size-sm"
      >Add new:</span
    >
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-shopping-cart-1"></i></span>
      <span class="navi-text">Order</span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-calendar-8"></i></span>
      <span class="navi-text">Event</span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-graph-1"></i></span>
      <span class="navi-text">Report</span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-rocket-1"></i></span>
      <span class="navi-text">Post</span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-writing"></i></span>
      <span class="navi-text">File</span>
    </a>
  </li>
</ul>
<!--end::Navigation-->
