<!--begin::Container-->
<div class="d-flex align-items-stretch justify-content-between container-fluid bg-white"
    style="box-shadow: 0px 0px 40px 0px rgba(82, 63, 105, 0.1); height: 7rem; border-bottom: solid 2px orangered">
    <nav class="navbar navbar-light">
        <a class="navbar-brand">
            <img src="./assets/media/logos/logo.png"  alt="Valloriza" loading="lazy" />
        </a>
    </nav> 
    <div class="d-flex align-items-stretch flex-shrink-0 mt-10">
        <app-topbar class="d-flex align-items-stretch flex-shrink-0"></app-topbar>
      </div>
</div>