import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './_layout/layout.component';
import { AuthGuard } from '../modules/auth/_services/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: 'certificados',
        loadChildren: () =>
          import('../pages/certificado/certificado.module').then((m) => m.CertificadoModule),
      },
      {
        path: 'pagamento',
        loadChildren: () =>
          import('../pages/pagamento/pagamento.module').then((m) => m.PagamentoModule),
      },
      {
        path: 'carrinho',
        loadChildren: () =>
          import('../pages/carrinho/carrinho.module').then((m) => m.CarrinhoModule),

      },
      {
        path: 'identificacao',
        loadChildren: () =>
          import('../pages/identificacao/identificacao.module').then((m) => m.IdentificacaoModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'confirmacao',
        loadChildren: () =>
          import('../pages/confirmacao/confirmacao.module').then((m) => m.ConfirmacaoModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'link-pagamento/:hashLink',
        loadChildren: () =>
          import('../pages/link-pagamento/link-pagamento.module').then((m) => m.LinkPagamentoModule),
      },
      {
        path: 'minha-conta',
        loadChildren: () =>
          import('../pages/minha-conta/minha-conta.module').then((m) => m.MinhaContaModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'detalhes-pedido',
        loadChildren: () =>
          import('../pages/detalhes-pedido/detalhes-pedido.module').then((m) => m.DetalhesPedidoModule),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('../pages/certificado/certificado.module').then((m) => m.CertificadoModule),
      },
      {
        path: '**',
        redirectTo: 'error/404',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule { }
