<!--begin::Navigation-->
<ul class="navi navi-hover">
  <li class="navi-header font-weight-bold py-4">
    <span class="font-size-lg">Choose Label:</span>
    <i
      class="flaticon2-information icon-md text-muted"
      data-toggle="tooltip"
      data-placement="right"
      title="Click to learn more..."
    ></i>
  </li>
  <li class="navi-separator mb-3 opacity-70"></li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-text">
        <span class="label label-xl label-inline label-light-success"
          >Customer</span
        >
      </span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-text">
        <span class="label label-xl label-inline label-light-danger"
          >Partner</span
        >
      </span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-text">
        <span class="label label-xl label-inline label-light-warning"
          >Suplier</span
        >
      </span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-text">
        <span class="label label-xl label-inline label-light-primary"
          >Member</span
        >
      </span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-text">
        <span class="label label-xl label-inline label-light-dark">Staff</span>
      </span>
    </a>
  </li>
  <li class="navi-separator mt-3 opacity-70"></li>
  <li class="navi-footer py-4">
    <a class="btn btn-clean font-weight-bold btn-sm cursor-pointer">
      <i class="ki ki-plus icon-sm"></i>
      Add new
    </a>
  </li>
</ul>
<!--end::Navigation-->
