<!--begin::Topbar-->
<ng-container *ngIf="extrasCartDisplay">
  <ng-container *ngIf="extrasCartLayout === 'dropdown'">
    <!--begin::Cart-->
    <div class="dropdown" ngbDropdown placement="bottom-right">
      <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
        <a routerLink="/carrinho" class="btn btn-icon btn-clean btn-dropdown btn-lg mr-1">
          <i class="fas fa-shopping-cart icon-xl"></i>
          <span class="cart-basket d-flex align-items-center justify-content-center">
            0
          </span>
        </a>
      </div>
    </div>
    <!--end::Cart-->
  </ng-container>
</ng-container>

<ng-container *ngIf="extrasUserDisplay">
  <ng-container *ngIf="extrasUserLayout === 'offcanvas'">
    <ng-container *ngIf="user$ | async as _user">
      <!--begin::User-->
      <div class="topbar-item">
        <div class="btn btn-icon btn-icon-mobile w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_toggle">
          <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Hi,</span>
          <span
            class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ _user.fullname }}</span>
            <span class="symbol symbol-lg-35 symbol-25 symbol-light-success">
              <span class="symbol-label font-size-h5 font-weight-bold">{{
                _user.fullname[0]
              }}</span>
          </span>
        </div>
      </div>
      <!--end::User-->
    </ng-container>
  </ng-container>

  <ng-container *ngIf="extrasUserLayout === 'dropdown'">
    <!--begin::User-->
    <ng-container>
      <div *ngIf="!(user$ | async)" class="dropdown" ngbDropdown placement="bottom-right">
        <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
          <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
            <i class="fas fa-user-alt icon-xl"></i>
          </div>
        </div>
        <div ngbDropdownMenu
          class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
          <app-user-dropdown-inner></app-user-dropdown-inner>
        </div>
      </div>
      <div *ngIf="user$ | async as _user" class="dropdown" ngbDropdown placement="bottom-right">
        <div class="topbar-item" data-toggle="dropdown" data-offset="0px,0px" ngbDropdownToggle>
          <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
            <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Olá,</span>
            <span class="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{ _user.nome }}</span>
            <span class="symbol symbol-35 symbol-light-warning">
              <span class="symbol-label font-size-h5 font-weight-bold">{{
                _user.nome | firstLetter
              }}</span>
            </span>
          </div>
        </div>
        <!--end::Toggle-->
        <!--begin::Dropdown-->
        <div ngbDropdownMenu
          class="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">
          <app-user-dropdown-inner></app-user-dropdown-inner>
        </div>
      </div>
      <!--end::Dropdown-->
    </ng-container>
    <!--end::User-->
  </ng-container>
</ng-container>

<!--end::Topbar-->