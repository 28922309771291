import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/_services/auth.service';
import { environment } from 'src/environments/environment';

import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// Highlight JS
import { HighlightModule } from 'ngx-highlightjs';
import { SplashScreenModule } from './_metronic/partials/layout/splash-screen/splash-screen.module';


import { DemoMaterialModule } from './../app/material-module'
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import {NgxMaskModule} from 'ngx-mask';
import { UtilsService } from './_metronic/core/utils/utils.service';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { InterceptorModule } from './interceptors/interceptor.module';
import { LoaderModule } from './components/loader/loader.module';

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SplashScreenModule,
    TranslateModule.forRoot(),
    HttpClientModule,
    HighlightModule,
    ClipboardModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    NgxMaskModule.forRoot(),
    NgbModule,
    DemoMaterialModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    DemoMaterialModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    NgxLoadingModule.forRoot({
      fullScreenBackdrop: true,
      backdropBackgroundColour: 'rgba(0,0,0,0.2)', 
      backdropBorderRadius: '4px',
      primaryColour: '#ffffff', 
      secondaryColour: '#ffffff', 
      tertiaryColour: '#ffffff'
    }),
    InterceptorModule, 
    LoaderModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    FormBuilder,
    UtilsService
  ],
  bootstrap: [AppComponent],

})
export class AppModule { }


