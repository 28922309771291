<!--begin::Navigation-->
<ul class="navi navi-hover py-5">
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-drop"></i></span>
      <span class="navi-text">New Group</span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-list-3"></i></span>
      <span class="navi-text">Contacts</span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-rocket-1"></i></span>
      <span class="navi-text">Groups</span>
      <span class="navi-link-badge">
        <span class="label label-light-primary label-inline font-weight-bold"
          >new</span
        >
      </span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-bell-2"></i></span>
      <span class="navi-text">Calls</span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-gear"></i></span>
      <span class="navi-text">Settings</span>
    </a>
  </li>

  <li class="navi-separator my-3"></li>

  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-magnifier-tool"></i></span>
      <span class="navi-text">Help</span>
    </a>
  </li>
  <li class="navi-item">
    <a class="navi-link cursor-pointer">
      <span class="navi-icon"><i class="flaticon2-bell-2"></i></span>
      <span class="navi-text">Privacy</span>
      <span class="navi-link-badge">
        <span class="label label-light-danger label-rounded font-weight-bold"
          >5</span
        >
      </span>
    </a>
  </li>
</ul>
<!--end::Navigation-->
