import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { AuthModel } from '../_models/auth.model';
import { AuthHTTPService } from './auth-http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { Cliente } from 'src/app/models/cliente';
import { Cart } from '../_models/cart';
import { UtilsService } from 'src/app/_metronic/core/utils/utils.service';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUserEcommerce$: Observable<User>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<User>;
  isLoadingSubject: BehaviorSubject<boolean>;
  currentCart$: Observable<Cart>;
  currentCartSubject: BehaviorSubject<Cart>;

  get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: User) {
    this.currentUserSubject.next(user);
  }

  constructor(
    private authHttpService: AuthHTTPService,
    private router: Router,
    private _utilsService: UtilsService,
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUserEcommerce')));
    this.currentUserEcommerce$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.currentCartSubject = new BehaviorSubject<Cart>(JSON.parse(localStorage.getItem('currentCart')));
    this.currentCart$ = this.currentCartSubject.asObservable();
    const subscr = this.getUserByToken().subscribe();
    this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<User> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.login(email, password).pipe(
      map((res: any) => {
        let user = res['data'];
        let cart: Cart = JSON.parse(localStorage.getItem("currentCart"));
        
        if(cart == null){
          cart = new Cart({}).getEmpty();
        }
        if (user && user.token) {
          user.remember = "RememberAfterLogin";
          localStorage.setItem('currentUserEcommerce', JSON.stringify(user));
          localStorage.setItem('currentCart', JSON.stringify(cart));
        }
        console.log(JSON.parse(localStorage.getItem("currentUserEcommerce")))
        this.currentUserSubject.next(user);
        this.currentCartSubject.next(cart);
      }, error => {
        console.log(error)
      }),
      switchMap(() => this.getUserByToken()),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }



  logout() {
    localStorage.removeItem(this.authLocalStorageToken);
    if (!(localStorage.getItem('currentUserEcommerce') === null)) {
      localStorage.removeItem('currentUserEcommerce');
      this.currentUserSubject.next(null);
    }
    if (!(localStorage.getItem('currentCart') === null)) {
      localStorage.removeItem('currentCart');
    }
    this.router.navigate(['/login'], {
      queryParams: {},
    });
  }

  getUserByToken(): Observable<User> {
    var auth : any = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.authHttpService.getUserByToken(auth.token).pipe(
      map((user: User) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<User>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  // need create new user then login
  registration(user: Cliente): Observable<any> {
    this.isLoadingSubject.next(true);
    return this.authHttpService.createUser(user).pipe(
      map(() => {
        this.isLoadingSubject.next(false);
      }, error => {
        console.log(error)
        console.log(error['error']['errors'][0])
        return of(undefined);
      }),
      switchMap(() => this.login(user.email, user.password)),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  forgotPassword(email: string): Observable<boolean> {
    this.isLoadingSubject.next(true);
    return this.authHttpService
      .forgotPassword(email)
      .pipe(finalize(() => this.isLoadingSubject.next(false)));
  }

  // private methods
  private setAuthFromLocalStorage(auth: User): boolean {
    // store auth authToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  private getAuthFromLocalStorage(): AuthModel {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

}
