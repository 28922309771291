import { Product } from './product';
import { Cliente } from './cliente';

export class Cart {

  public client: Cliente;
  public codigoCRM: string;
  public payer: Cliente;

  public products: Product[];
  public productsEcommerce: Product[];

  public entrega: number;
  public discount: number;
  public dataContrato: string;

  public totalItens: number;
  public valorTotal: number;
  public subTotal: number;

  public cupom: string;

  public tipoCertificado: string;

  public paymentForm: string;

  constructor(json: any) {
    this.products = json.products;
    this.discount = json.discount;
    this.client = json.client;
    this.payer = json.payer;
    this.codigoCRM = json.codigoCRM;
    this.entrega = json.entrega;
    this.dataContrato = json.dataContrato;
    this.totalItens = json.totalItens;
    this.valorTotal = json.valorTotal;
    this.subTotal = json.subTotal;
    this.cupom = json.cupom;
    this.productsEcommerce = json.productsEcommerce;
    this.tipoCertificado = json.tipoCertificado;
  }

  public getEmpty(): Cart {
    this.client = undefined;
    this.products = [];
    this.productsEcommerce = [];
    this.discount = 0;
    this.payer = undefined;
    this.codigoCRM = undefined;
    this.entrega = 0;
    this.dataContrato = '';
    this.totalItens = 0;
    this.valorTotal = 0;
    this.subTotal = 0;
    this.cupom = '';
    this.tipoCertificado = '';

    return this;
  }

  public get(attribute: string): any {
    return this[attribute];
  }

  public set(attribute: string, value: any) {
    this[attribute] = value;
  }
}
