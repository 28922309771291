<!--begin::Container-->
<div
  [ngClass]="footerContainerCSSClasses"
  class="d-flex flex-column flex-md-row align-items-center justify-content-between"
>
  <!--begin::Copyright-->
  <div class="text-dark order-2 order-md-1">
    <span class="text-muted font-weight-bold mr-2"
      >{{ currentYear }} &copy;</span
    >
    <a
      class="text-dark-75 text-hover-primary"
      >rrcoelho</a
    >
  </div>
  <!--end::Copyright-->

  <!--begin::Nav-->
  <div class="nav nav-dark">
  </div>
  <!--end::Nav-->
</div>
<!--end::Container-->
