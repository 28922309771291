// BRASIL
export const locale = {
  lang: 'pt_BR',
  data: {
    MENU: {    
      DASHBOARD: 'Dashboard',
      LINK_PAGAMENTO: 'Pagamento'
    },
    AUTH: {
      GENERAL: {
        SIGNUP_BUTTON: 'Sign Up'
      }
    }
  }
};
