
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Cart } from 'src/app/modules/auth/_models/cart';

@Injectable()
export class UtilsService {

  pureResult: any;
  maskedId: any;
  DECIMAL_SEPARATOR = '.';
  GROUP_SEPARATOR = ',';

  constructor(private sanitizer: DomSanitizer) {
  }

  sanitizeBase64(comprovante) {
    return this.sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + comprovante);
  }

  public alertErro(error) {
    let errorStr = '';
    let errorJson = error;

    if (errorJson.error != null && errorJson.error.errors != null && errorJson.error.errors.length > 0) {
      for (let i = 0; i < errorJson.error.errors.length; i++) {
        errorStr += errorJson.error.errors[i] + '\n';
      }
    } else {
      errorStr = errorJson.message;
    }

    alert(errorStr);
  }

  formatDate(d) {
    console.log(d)
    return [d.getDate(),
    d.getMonth() + 1,
    d.getFullYear()].join('/') + ' ' +
      [d.getHours(),
      d.getMinutes(),
      d.getSeconds()].join(':');
  }

  public printRestResponse(title: string, obj: any) {
    console.log(title + ' \\/\\/\\/\\/\\/\\/\\/\\/\\/');
    console.log(obj);
  }

  public isFieldValid(field): boolean {
    if (!field || field == '')
      return false;

    return true;
  }

  // CEP
  cep_mask(valString) {
    if (!valString) {
      return '';
    }

    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    this.pureResult = parts;
    this.maskedId = this.cep(parts[0].substring(0, 8));

    return this.maskedId;
  };

  cep(v) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/^(\d{5})(\d)/, '$1-$2'); //Coloca hifen entre o quinto e o sexto dígitos
    return v;
  }

  unFormat(val) {
    if (!val) {
      return '';
    }
    val = val.replace(/\D/g, '');

    if (this.GROUP_SEPARATOR === ',') {
      return val.replace(/,/g, '');
    } else {
      return val.replace(/\./g, '');
    }
  };

  format(valString) {
    if (!valString) {
      return '';
    }

    let val = valString.toString();
    const parts = this.unFormat(val).split(this.DECIMAL_SEPARATOR);
    this.pureResult = parts;
    if (parts[0].length <= 11) {
      this.maskedId = this.cpf_mask(parts[0].substring(0, 11));
      return this.maskedId;
    } else {
      this.maskedId = this.cnpj(parts[0].substring(0, 14));
      return this.maskedId;
    }
  };

  cpf_mask(v) {
    v = v.replace(/\D/g, ''); // Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); // Coloca um ponto entre o terceiro e o quarto dígitos
    // de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  cnpj(v) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito

    v = v.replace(/^(\d{2})(\d)/, '$1.$2'); //Coloca ponto entre o segundo e o terceiro dígitos
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); //Coloca ponto entre o quinto e o sexto dígitos
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2'); //Coloca uma barra entre o oitavo e o nono dígitos
    v = v.replace(/(\d{4})(\d)/, '$1-$2'); //Coloca um hífen depois do bloco de quatro dígitos
    return v;
  }

  digit_mask(valString) {
    return valString.replace(/\s+/g, '').replace(/[^0-9]/gi, '').replace(/\D/g, '');
  }

  card_mask(cc) {
    if (!cc) {
      return '';
    }

    var cc = cc.replace(/\s+/g, '').replace(/[^0-9]/gi, '').replace(/\D/g, '');
    var matches = cc.match(/\d{4,16}/g);
    var match = matches && matches[0] || ''
    var parts = []

    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4))
    }

    if (parts.length) {
      return parts.join(' ')
    } else {
      return cc
    }
  }

  public validateObjectForm(obj, required, optional, combinaCampos) {
    var msgErrors: Array<String> = [];
    var tagName = undefined;
    for (var i = 0; i < required.length; i++) {
      tagName = undefined;
      if (obj[required[i]] == null || obj[required[i]] == '') {
        for (var a = 0; a < combinaCampos.length; a++) {
          if (required[i] == combinaCampos[a]['campo']) {
            tagName = combinaCampos[a]['tag'];
            break;
          }
        }
        msgErrors.push('O campo ' + (tagName !== undefined ? tagName : required[i]) + ' é obrigatório');
      }
    }

    for (var i = 0; i < optional.length; i++) {
      if (obj[optional[i]] === '') {
        obj[optional[i]] = null;
      }
    }

    return msgErrors;
  }

  public getRequiredOptionalFields(obj) {
    var required: Array<String> = [];
    var optional: Array<String> = [];

    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] === null) {
          optional.push(key);
        } else {
          required.push(key);
        }
      }
    }
    return [required, optional];
  }

  public sumCart(cart: Cart): number {
    if (cart.products.length === 0) {
      return 0;
    } else {
      let somaCarrinho = 0;
      for (const prod of cart.products) {
        somaCarrinho += prod.quantidade * prod.valor;
      }

      return somaCarrinho;
    }
  }

  public listaStrProdutos(cart: Cart) {
    let param = '';
    for (const prod of cart.products) {
      param += prod.id + ',';
    }

    return param;
  }

  getEmptyCart(): Cart {
    return new Cart({ totdiscount: 0, entrega: 0, products: [], productsEcommerce: [], payments: [] });
  }

  clearCart(cart) {
    cart = new Cart({}).getEmpty();
    localStorage.setItem('currentCart', JSON.stringify(cart));
  }

  dowloadPDF(base64PDF, filename) {
    var byteArray = this.base64ToUint8(base64PDF);
    var file = new File([byteArray], filename + '.pdf', { type: 'application/pdf' });

    return file;
  }

  base64ToUint8(base64PDF) {
    var Base64 = { _keyStr: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=', encode: function (e) { var t = ''; var n, r, i, s, o, u, a; var f = 0; e = Base64._utf8_encode(e); while (f < e.length) { n = e.charCodeAt(f++); r = e.charCodeAt(f++); i = e.charCodeAt(f++); s = n >> 2; o = (n & 3) << 4 | r >> 4; u = (r & 15) << 2 | i >> 6; a = i & 63; if (isNaN(r)) { u = a = 64 } else if (isNaN(i)) { a = 64 } t = t + this._keyStr.charAt(s) + this._keyStr.charAt(o) + this._keyStr.charAt(u) + this._keyStr.charAt(a) } return t }, decode: function (e) { var t = ''; var n, r, i; var s, o, u, a; var f = 0; e = e.replace(/[^A-Za-z0-9+/=]/g, ''); while (f < e.length) { s = this._keyStr.indexOf(e.charAt(f++)); o = this._keyStr.indexOf(e.charAt(f++)); u = this._keyStr.indexOf(e.charAt(f++)); a = this._keyStr.indexOf(e.charAt(f++)); n = s << 2 | o >> 4; r = (o & 15) << 4 | u >> 2; i = (u & 3) << 6 | a; t = t + String.fromCharCode(n); if (u != 64) { t = t + String.fromCharCode(r) } if (a != 64) { t = t + String.fromCharCode(i) } } t = Base64._utf8_decode(t); return t }, _utf8_encode: function (e) { e = e.replace(/rn/g, 'n'); var t = ''; for (var n = 0; n < e.length; n++) { var r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r) } else if (r > 127 && r < 2048) { t += String.fromCharCode(r >> 6 | 192); t += String.fromCharCode(r & 63 | 128) } else { t += String.fromCharCode(r >> 12 | 224); t += String.fromCharCode(r >> 6 & 63 | 128); t += String.fromCharCode(r & 63 | 128) } } return t }, _utf8_decode: function (e) { var t = ''; var n = 0; var r = 0; var c2 = 0; while (n < e.length) { r = e.charCodeAt(n); if (r < 128) { t += String.fromCharCode(r); n++ } else if (r > 191 && r < 224) { c2 = e.charCodeAt(n + 1); t += String.fromCharCode((r & 31) << 6 | c2 & 63); n += 2 } else { c2 = e.charCodeAt(n + 1); var c3 = e.charCodeAt(n + 2); t += String.fromCharCode((r & 15) << 12 | (c2 & 63) << 6 | c3 & 63); n += 3 } } return t } }

    var byteCharacters = this.decodeBase64(base64PDF);
    var byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    return new Uint8Array(byteNumbers);
  }

  decodeBase64(s) {
    var e = {}, i, b = 0, c, x, l = 0, a, r = '', w = String.fromCharCode, L = s.length;
    var A = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
    for (i = 0; i < 64; i++) { e[A.charAt(i)] = i; }
    for (x = 0; x < L; x++) {
      c = e[s.charAt(x)]; b = (b << 6) + c; l += 6;
      while (l >= 8) { ((a = (b >>> (l -= 8)) & 0xff) || (x < (L - 2))) && (r += w(a)); }
    }
    return r;
  }

}