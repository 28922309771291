import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserModel } from '../../_models/user.model';
import { environment } from '../../../../../environments/environment';
import { AuthModel } from '../../_models/auth.model';

import { map } from 'rxjs/operators';
import { Cliente } from 'src/app/models/cliente';
import { User } from '../../_models/user';

const API_USERS_URL = `${environment.apiUrl}/api/usuario/auth`;

@Injectable({
  providedIn: 'root',
})
export class AuthHTTPService {
  private _headers: HttpHeaders;

  constructor(private http: HttpClient) { }

  setToken() {
    let currentUserEcommerce: User;
    let currentUserJson: String = JSON.parse(localStorage.getItem('currentUserEcommerce'));

    if (currentUserJson != null) {
      currentUserEcommerce = new User(currentUserJson);

      this._headers = new HttpHeaders({
        'Content-Type': 'application/json; charset=utf-8',
        'Authorization': 'Bearer ' + currentUserEcommerce.get('token')
      })
    }
  }


  // public methods
  login(cpfCnpj: string, password: string): Observable<any> {
    let params = JSON.stringify({ login: cpfCnpj, senha: password });
    let headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    })

    return this.http.post(`${API_USERS_URL}/ecommerce`, params, { headers: headers });
  }

  // CREATE =>  POST: add a new user to the server
  createUser(cliente: Cliente): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    })

    let params = JSON.stringify({
      cpfCnpj: cliente.cpfCnpj,
      nome: cliente.nome,
      endereco: cliente.endereco,
      bairro: cliente.bairro,
      cidade: cliente.cidade,
      cep: cliente.cep,
      fone: cliente.fone,
      celular: cliente.celular,
      email: cliente.email,
      uf: cliente.uf,
      dataNascimento: cliente.dataNascimento,
      tipoPessoa: cliente.tipoPessoa,
      sexo: cliente.sexo,
      pessoaReferencia: cliente.pessoaReferencia,
      telefoneReferencia: cliente.telefoneReferencia,
      idIndicador: cliente.idIndicador,
      prospeccaoIndicador: cliente.prospeccaoIndicador,
      cpfPessoaReferencia: cliente.cpfPessoaReferencia,
      inscricaoEstadual: cliente.inscricaoEstadual,
      acessaNet: 'S',
      senhaNet: cliente.senhaNet
    });

    return this.http.post(`${environment.apiUrl}` + "/api/ecommerce/usuario/cadastrar", params, { headers: headers });
  }

  // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
  forgotPassword(email: string): Observable<boolean> {
    return this.http.post<boolean>(`${environment.apiUrl}` + "/api/email/forgot-password", {
      email,
    });
  }

  getUserByToken(token): Observable<any> {
    const httpHeaders = new HttpHeaders({
      Authorization: `Bearer ${token}`,
    });
    return this.http.get<any>(`${API_USERS_URL}/me`, {
      headers: httpHeaders,
    });
  }

  logout() {
    // remove user from local storage to log user out
    if (!(localStorage.getItem('currentUserEcommerce') === null)) {
      localStorage.removeItem('currentUserEcommerce');
    }

    if (!(localStorage.getItem('currentCart') === null)) {
      localStorage.removeItem('cart');
    }
  }

  getAddressByCEP(cep: string): Observable<any> {
    return this.http.get(`https://viacep.com.br/ws/${cep}/json/`);
  }

  verifyRecaptcha(secret, response){
    let params = JSON.stringify({ secret: secret, response: response });
    return this.http.post(`https://www.google.com/recaptcha/api/siteverify`, params);
  }

  changePassword(idUsuario, senhaAtual, novaSenha, hashId, email): Observable<any>{
    let headers: HttpHeaders = new HttpHeaders({
      "Content-Type": "application/json; charset=utf-8",
    })
    let params = JSON.stringify({ idUsuario: idUsuario, senhaAtual: senhaAtual, novaSenha: novaSenha, usuarioEmail: hashId, email: email});
 
    return this.http.post(`${environment.apiUrl}` + "/api/ecommerce/usuario/change-password", params, { headers: headers });
  }

  getClienteLogado(id): Observable<any>{
    this.setToken();
    return this.http.get<any>(`${environment.apiUrl}` + "/api/ecommerce/pessoa/" + id, { headers: this._headers });
  }
}
