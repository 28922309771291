<ng-container >
   <!--begin::Nav-->
  <div *ngIf="!(user$ | async)" class="navi navi-spacer-x-0 pt-5">
    <!--begin::Item-->
    <a class="navi-item px-8 cursor-pointer" routerLink="/login">
      <div class="navi-link">
        <div class="navi-icon mr-2">
          <i class="fas fa-user-alt text-warning"></i>
        </div>
        <div class="navi-text">
          <div class="font-weight-bold font-size-h6">
            Entrar
          </div>
        </div>
      </div>
    </a>
    <!--end::Item-->
    <div class="navi-separator mt-3"></div>

  </div>

  <div *ngIf="user$ | async as _user" class="navi navi-spacer-x-0 pt-5">
    <!--begin::Item-->
    <a class="navi-item px-8 cursor-pointer" routerLink="/minha-conta">
      <div class="navi-link">
        <div class="navi-icon mr-2">
          <i class="fas fa-user-alt text-warning"></i>
        </div>
        <div class="navi-text">
          <div class="font-weight-bold font-size-h6">
            Minha Conta
          </div>
        </div>
      </div>
    </a>
    <!--end::Item-->

    <!--begin::Footer-->
    <div class="navi-separator mt-3"></div>
    <div class="navi-footer px-8 py-5 text-align-right">
      <a
        class="btn btn-light-warning font-weight-bold cursor-pointer"
        (click)="logout()"
        >Sair</a
      >
    </div>
    <!--end::Footer-->
  </div>
  <!--end::Nav-->
</ng-container>
